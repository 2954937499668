import React, { Component } from 'react'
import Container from '../costra/Container.js'
import Translate from '../costra/Translate.js'
import EditData from '../costra/EditData.js'

import LoadingPlaceholder from '../utils/LoadingPlaceholder.js'

import UsersArray from '../users/UsersArray.js'
import PreparedLines from './PreparedLines.js'
import Erl from './Erl.js'
import Sal from './Sal.js'
import VwFs from './VwFs.js'
import Ucb from './Ucb.js'
import Cofidis from './Cofidis.js'

import '../css/list.css'
import '../css/icons.css'

class ImportProgress extends Component {

  constructor(props) {
    super(props)
    this.state = {
      state: null,
      params: {},
    }
    this.container = new Container(this, 'com.optimsys.costra.kordex.imports.ImportView')
    this.uploadFiles = this.uploadFiles.bind(this)
    this.data = new EditData(this, 'params.')
  }

  load() {
    this.container.tell({
      _class: 'com.optimsys.costra.kordex.imports.ImportView$Get',
    })
  }

  open(item) {
    this.container.tell({
      _class: 'com.optimsys.costra.kordex.imports.ImportView$Command',
      command: {
        _class: 'com.optimsys.costra.kordex.imports.message.OpenImport',
        importObject: item,
      },
    })
  }

  prepare() {
    this.container.tell({
      _class: 'com.optimsys.costra.kordex.imports.ImportView$Command',
      command: {
        _class: 'com.optimsys.costra.kordex.imports.message.PrepareImport',
        params: this.state.params,
      },
    })
  }

  commit() {
    this.container.tell({
      _class: 'com.optimsys.costra.kordex.imports.ImportView$Command',
      command: {
        _class: 'com.optimsys.costra.kordex.imports.message.CommitImport',
      },
    })
  }

  kill() {
    this.container.tell({
      _class: 'com.optimsys.costra.kordex.imports.ImportView$Command',
      command: {
        _class: 'com.optimsys.costra.kordex.imports.message.KillImport',
      },
    })
  }

  uploadFiles(event) {
    //this.setState({_class: null})
    event.preventDefault()
    var data = new FormData(document.getElementById('importUploadForm'))
    fetch('/upload/import', {
      method: 'POST',
      body: data,
    }).then((result) => {
      //this.setState({uploading: false})
      if (result.status < 200 || result.status > 202) {
        this.setState({ error: 'imports.importProgress.uploadError' })
      }
    }, (error) => {
      this.setState({ error: 'imports.importProgress.uploadError' })
    })
  }

  componentDidMount() {
    this.load()
  }

  componentWillUnmount() {
    this.container.close()
  }

  renderPreview(data) {
    switch (data._class) {
      case 'com.optimsys.costra.kordex.imports.common.StandardImportPreview':
        return PreparedLines.standardPreview(this, data)
      case 'com.optimsys.costra.kordex.imports.common.StandardPaymentsPreview':
        return PreparedLines.standardPaymentsPreview(this, data)
      case 'com.optimsys.costra.kordex.imports.sal.telephony.TelE6PaymentsPreview':
        return Sal.telE6PaymentsPreview(this, data)

      case 'com.optimsys.costra.kordex.imports.sal.sf.PartnersPreview':
        return Sal.partnersImportPrepared(this, data)
      case 'com.optimsys.costra.kordex.imports.sal.pis.PisSkPostPreview':
        return Sal.pisSkPostPrepared(this, data)
      case 'com.optimsys.costra.kordex.imports.vw.fs.DailyPaymentsPreview':
        return VwFs.dailyPrepared(this, data)
      case 'com.optimsys.costra.kordex.imports.vw.fs.CaseUpdateTxtPreview':
        return VwFs.caseUpdateImportPrepared(this, data)
      default:
        return <div>{data._class}</div>
    }
  }

  renderResult(data) {
    switch (data._class) {
      case 'com.optimsys.costra.kordex.imports.sal.telephony.TelE4Result':
        return Sal.telE4Result(this, data)
      case 'com.optimsys.costra.kordex.imports.sal.telephony.TelE6Result':
        return Sal.telE6Result(this, data)
      case 'com.optimsys.costra.kordex.imports.sal.telephony.TelPaymentsResult':
        return Sal.telPaymentResult(this, data)
      case 'com.optimsys.costra.kordex.imports.sal.pis.PisE4Result':
        return Sal.pisE4Result(this, data)
      case 'com.optimsys.costra.kordex.imports.sal.pis.PisE6Result':
        return Sal.pisE6Result(this, data)
      case 'com.optimsys.costra.kordex.imports.sal.collection.CollectionE4Result':
        return Sal.collectionE4Result(this, data)
      case 'com.optimsys.costra.kordex.imports.sal.collection.CollectionPaymentsResult':
        return Sal.telPaymentResult(this, data)
      case 'com.optimsys.costra.kordex.imports.sal.collection.CollectionE6Result':
        return Sal.collectionE6Result(this, data)

      case 'com.optimsys.costra.kordex.imports.erl.pred.PredImportResult':
        return Erl.predImportDone(this, data)
      case 'com.optimsys.costra.kordex.imports.erl.pred.PredImportXmlResult':
        return Erl.predXmlImportDone(this, data)
      case 'com.optimsys.costra.kordex.imports.erl.pred.PredE6Result':
        return Erl.predE6ImportDone(this, data)

      case 'com.optimsys.costra.kordex.imports.sal.sf.PartnersResult':
        return Sal.partnersImportDone(this, data)
      case 'com.optimsys.costra.kordex.imports.sal.sf.SfCarsResult':
        return Sal.sfCarsImportDone(this, data)
      case 'com.optimsys.costra.kordex.imports.sal.sf.SfVtpResult':
        return Sal.sfVtpImportDone(this, data)
      case 'com.optimsys.costra.kordex.imports.sal.sf.SfE6Result':
        return Sal.sfE6ImportDone(this, data)
      case 'com.optimsys.costra.kordex.imports.sal.pis.PisSKImportResult':
        return Sal.pisSkResult(this, data)
      case 'com.optimsys.costra.kordex.imports.sal.pis.PisSkPostResult':
        return Sal.pisSkPostResult(this, data)
      case 'com.optimsys.costra.kordex.imports.vw.fs.VwFsImportResult':
        return VwFs.checkupsImportDone(this, data)
      case 'com.optimsys.costra.kordex.imports.vw.fs.DailyPaymentsImportResult':
        return VwFs.dailyImportDone(this, data)
      case 'com.optimsys.costra.kordex.imports.vw.fs.CaseUpdateTxtImportResult':
        return VwFs.caseUpdateImportDone(this, data)

      case 'com.optimsys.costra.kordex.imports.ucb.telephony.TelResult':
        return Ucb.telCaseResult(this, data)
      case 'com.optimsys.costra.kordex.imports.ucb.telephony.TelUpdateResult':
        return Ucb.telCaseUpdateResult(this, data)

      case 'com.optimsys.costra.kordex.imports.cofidis.collection.CollectionResult':
        return Cofidis.collectionCaseResult(this, data)
      case 'com.optimsys.costra.kordex.imports.cofidis.collection.CollectionPaymentsResult':
        return Cofidis.collectionCasePaymentsResult(this, data)

      default:
        return <div>{data._class}</div>
    }
  }

  renderOperators() {
    const filterFn = (user) => {
      let id = user.id.$oid
      return (this.state.state.importObject.operators.find(oId => oId.$oid === id)) ? true : false
    }
    return <UsersArray data={this.data}
                       disabled={false}
                       path="operators"
                       title='imports.assignOperators'
                       filterFn={filterFn}
                       icon="user"/>
  }

  render() {
    if (!this.state.state) {
      return <LoadingPlaceholder />
    }
    switch (this.state.state._class) {

      case 'com.optimsys.costra.kordex.imports.message.ImportState$NoImport':
        return <ul className="list spacedTop">
          {this.state.state.availableImports && this.state.state.availableImports.map(item =>
            <li key={item.campaignId.$oid + '-' + item._class}>
              <div className="row withHover"
                onClick={(event) => this.open(item)}>
                <div><div className="icon import" /></div>
                <div className="size4c">{item.campaignName}</div>
                <div className="size8c">{Translate.get(item._class)}</div>
              </div>
            </li>
          )}
        </ul>

      case 'com.optimsys.costra.kordex.imports.message.ImportState$PreImport':
        return <form id="importUploadForm" onSubmit={this.uploadFiles}>
          <div className="section spacedTop">
            <div className="listHeader">
              <div>
                <div className="icon iconBig import"/>
                <h2>{Translate.get(this.state.state.importObject._class)}</h2>
              </div>
              <div >
                {this.state.state.canBePrepared && PreparedLines.prepareButton(this)}
                {PreparedLines.closeButton(this)}
              </div>
            </div>
            <div className="subsection">
              {this.state.state.importObject.files && this.state.state.importObject.files.map(file =>
                <div className="content" key={file.id}>
                  <div className="formRow">
                    <label>
                      {Translate.get(this.state.state.importObject._class + '.' + file.id) +
                        (file.optional ? ' ' + Translate.get('imports.file.optional') : '')}:
                    </label>
                    {this.state.state.uploadedFiles && this.state.state.uploadedFiles[file.id] ?
                      this.state.state.uploadedFiles[file.id]
                      :
                      <input type="file" name={file.id} className="formInput" />
                    }
                  </div>
                </div>
              )}
              {!this.state.state.allFilesUploaded &&
                <div className="content">
                  <div className="formRow">
                    <input type="submit" value={Translate.get('imports.button.upload')} />
                  </div>
                </div>
              }
            </div>
            {this.state.state.importObject.operators && this.renderOperators()}
          </div>
        </form>

      case 'com.optimsys.costra.kordex.imports.message.ImportState$Preparing':
        return <div className="listHeader">
          <div>
            <div className="icon iconBig loading"/>
            <h2>{Translate.get('imports.preparing.title')}</h2>
          </div>
          <div>{PreparedLines.closeButton(this)}</div>
        </div>

      case 'com.optimsys.costra.kordex.imports.message.ImportState$Preview':
        return this.renderPreview(this.state.state.data)

      case 'com.optimsys.costra.kordex.imports.message.ImportState$Committing':
        return <div className="listHeader">
          <div>
            <div className="icon iconBig loading" />
            <h2>{Translate.get('imports.committing.title')}</h2>
          </div>
          <div>{PreparedLines.closeButton(this)}</div>
        </div>

      case 'com.optimsys.costra.kordex.imports.message.ImportState$Done':
        return this.renderResult(this.state.state.data)

      case 'com.optimsys.costra.kordex.imports.message.ImportState$Error':
        return <>
          <div className="listHeader">
            <div>
              <div className="icon iconBig error" />
              <h2>{Translate.get('imports.error.title')}</h2>
            </div>
            <div>{PreparedLines.closeButton(this)}</div>
          </div>
          <div>
            <div>{this.state.state.message}</div>
          </div>
        </>
      default:
        return <div>{this.state.state._class}</div>
    }
  }
}

export default ImportProgress